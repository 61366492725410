$color: #e2161a;
// Google font
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap');

// Primary bootstrap color
$primary: #FF6565;

// Bootstrap
@import './node_modules/bootstrap/scss/bootstrap.scss';

/* Fontawesome styles */
@import '@fortawesome/fontawesome-svg-core/styles.css';

html,
body {
  padding: 0 !important;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; */
  /* font-family: 'Nunito Sans',sans-serif; */
  font-family: 'Montserrat', sans-serif;
  /* background-color: #F8F8F8; */
  // height: 100%;
  // display: flex;
  // height: 100%;
  // width: 100%;
  // flex-direction: column;
  overflow-x: hidden;
}

pre {
  font-family: 'Montserrat', sans-serif !important;
  width: 100%;
  overflow-x: auto;
  white-space: pre-wrap;
  border: 0;
}

a:hover,
a {
  text-decoration: none;
}

.btn {
  border-radius: 12px !important;
}

.card,
.card-body {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 24px !important;
}

.card-header {
  border-top-left-radius: 24px !important;
  border-top-right-radius: 24px !important;
}

/* Input group  */
.input-group input,
.input-group .input-group-text {
  border-radius: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

select {
  border-radius: 12px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

/* Pagination design */
a.page-link {
  border-radius: 24px !important;
  margin-right: 2px !important;
  margin-left: 2px !important;
}

ul.pagination {
  margin-bottom: 0px;
}

/* event */
.booth:hover {
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.button-tab:focus,
.button-tab:active {
  box-shadow: none !important;
}


/* Event page */
.booth-no-card,
.booth-content-card {
  width: 80%;
}

.booth-company-avatar {
  width: 80px;
  height: 80px;
}

.booth-company-avatar img {
  width: 90%;
}

.booth .hanger {
  height: 40px;
  background-color: #2E3238;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 100%;
}

.card-header {
  border-color: #EBEBEB;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}

.inst-descr {
  max-height: 6em;
  overflow: hidden;
}

@media only screen and (max-width: 768px) {

  .booth-no-card,
  .booth-content-card {
    width: 100%;
  }

  .booth-content-card h2 {
    font-size: 12px !important;
    height: 4em !important;
  }

  .booth-company-avatar {
    width: 40px;
    height: 40px;
  }

  .booth-company-avatar img {
    width: 90%;
  }

  .booth .hanger {
    height: 20px;
    background-color: #2E3238;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 100%;
  }
}

/* Job list */
.badge {
  margin-right: 5px;
}

.pull-right {
  text-align: right;
}

.navbar .navbar-nav > .active {
  color: #FF6565 !important;
  font-weight: 600 !important;
}

.slick-dots {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.slick-dots li.slick-active {
  background-color: #FF6565 !important;
  width: 30px;
  height: 10px;
  border-radius: 100px;
}

.slick-dots li.slick-active > button {
  color: #283153 !important;
}

.slick-dots li.slick-active > button::before {
  display: none;
}

.nav-link {
  margin: 0;
  padding: 0;
}