$color: #e2161a;
.navbar {
  box-shadow: rgba(0, 0, 0, 0.01) 0px 1px 1px 0px, rgba(0, 0, 0, 0.06) 0px 1px 1px 0px;
  min-height: 70px;
}

.createNewJobLanguage {
  cursor: pointer;
  padding-bottom: 0px;
  border-radius: 10px;
  min-height: 160px;
}

.brand {
  cursor: pointer;
}

.languageIcon {
  width: 75%;
}

.shareBtn {
  text-align: center;
  justify-content: center;
}

.shareBtn button {
  margin-left: 3px;
  margin-right: 3px;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .createNewJobLanguage {
    min-height: 100px;
  }

  .languageIcon {
    width: 25%;
    margin-right: 1rem;
  }
}