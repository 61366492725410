$color: #e2161a;
.shareBtnContainer {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.shareBtnContainer a {
  text-align: center;
}